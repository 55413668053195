
import $ from 'jquery';

// import 'bootstrap/dist/js/bootstrap.bundle';

// import 'bootstrap/js/dist/tooltip';

/**
 * Various jQuery scripts for AISIDP.
 * Includes:
 *  - validation
 *  - button processing transforms (ref: loginuserpass.js)
 */
$(document).ready(function () {

  /* Add 'active' class to link of current page if link in the nav bar */
  function updateActiveLinks() {
    const locationArray = window.location.pathname.split('/'); // get all parts of urls split by '/'
    // console.log(locationArray);
    const page = locationArray[locationArray.length - 1];
    // console.log(page);
    const navLinks = document.querySelectorAll('.nav-link');
    const currentLink = [...navLinks]
      .filter(navLink => navLink.href !== undefined)
      .find(link => link.href.includes(page));
    if (currentLink !== undefined) {
      // console.log('currentLink', currentLink);
      currentLink.classList.add('active');
    }
  }
  updateActiveLinks();

  /*  
    Form validation and submit handling.
    First validates form using Bootstrap 4 / html validation.
    If valid, disables button and transforms button text   
  */
  let form = $('.ais-form');

  $(form).submit(function (e) {
    // alert('submit');
    if (!validateForm(this)) {
      $(this).addClass('was-validated');
      e.preventDefault();
      e.stopPropagation();
    }
    else // passed client-side validation
    {
      let btn = $('.btn-ais-submit');
      $(btn).attr('disabled', 'disabled');
      $(btn).html(btn.data('submit-text')); // change text to value given in 'data-submit-text' attr 
    }

  });

  function validateForm(aForm) {
    // alert('in validateForm(aForm)');

    // First check html attribute validity and return false if not valid
    if (aForm.checkValidity() === false) {
      return false;
    }

    /* TODO add other validation checks, such as password matching */


    return true; // assume all other validation checks passed
  }

  /*
    Show password. 
    Attach an event listener to the input group that holds the pw field
  */
  $('#pw1Group > .showPasswordIcon > span').on('click', function (e) {
    event.preventDefault();
    showHidePasswordGroup('#pw1Group');
  });

  $('#pw2Group > .showPasswordIcon > span').on('click', function (e) {
    event.preventDefault();
    showHidePasswordGroup('#pw2Group');
  });

  /**
   * Show or hide the input text in the password field of this password group.
   * See: change_pw.twig for an example of how markup needs to be formatted.
   * @param {*} aPasswordGroup 
   */
  function showHidePasswordGroup(aPasswordGroup) {
    //  $(aPasswordGroup).find(selector2);
    // alert(aPasswordGroup.childNodes);
    const passwordGroup = $(aPasswordGroup);
    const passwordIcon = passwordGroup.find('span > i');
    const passwordInput = passwordGroup.children('input');

    if (passwordInput.attr("type") == "text") {
      passwordInput.attr('type', 'password');
      passwordIcon.addClass("fa-eye-slash");
      passwordIcon.removeClass("fa-eye");
    } else if (passwordInput.attr("type") == "password") {
      passwordInput.attr('type', 'text');
      passwordIcon.addClass("fa-eye");
      passwordIcon.removeClass("fa-eye-slash");
    }

      // if ($('#show_hide_password input').attr("type") == "text") {
      //   $('#show_hide_password input').attr('type', 'password');
      //   $('#show_hide_password i').addClass("fa-eye-slash");
      //   $('#show_hide_password i').removeClass("fa-eye");
      // } else if ($('#show_hide_password input').attr("type") == "password") {
      //   $('#show_hide_password input').attr('type', 'text');
      //   $('#show_hide_password i').removeClass("fa-eye-slash");
      //   $('#show_hide_password i').addClass("fa-eye");
      // }


      // alert('value of pw field: ' + passwordInput.val());
  }


  /* Button text transform 
  ----------------------------------------------------------------------*/
  // $('.btn-ais-submit').on('click', function () {

  //   // Bootstrap 4 validation
  //   if (validateForm()) {
  //     $(this).attr('disabled', 'disabled');
  //     $(this).html($(this).data('submit-text')); // change text to value given in 'data-submit-text' attr
  //     $(this).parents('form').submit();
  //   }
  //   // var form = $('.ais-form');
  //   // if (form[0].checkValidity() === false) {
  //   //   event.preventDefault();
  //   //   event.stopPropagation();
  //   // }
  //   // form.addClass('was-validated');


  //   // alert('clicked ais submit button');
  //   // needsValdation();
  //   // if (true) {
  //   // $(this).attr('disabled', 'disabled');
  //   // $(this).html($(this).data('submit-text')); // change text to value given in 'data-submit-text' attr
  //   // $(this).parents('form').submit();
  //   // }
  // });

  /* Validation
  ----------------------------------------------------------------------- */
  // function validateForm() {
  //   // alert('in validatForm()')

  //   // Fetch all the forms we want to apply custom Bootstrap validation styles to
  //   let form = $('.ais-form');

  //   if (form[0].checkValidity() === false) {
  //     event.preventDefault();
  //     event.stopPropagation();
  //   }
  //   else {
  //     return true;
  //   }
  //   form.addClass('was-validated');
  //   return false;
  // }

  /* Popover
  ---------------------------------------------------------------------- */
  // $("[data-toggle=popover]").popover();


});

/*
Copied from: https://getbootstrap.com/docs/4.4/components/forms/#validation
Example starter JavaScript for disabling form submissions if there are invalid fields
*/
// (function () {
//   'use strict';
//   window.addEventListener('load', function () {
//     // Fetch all the forms we want to apply custom Bootstrap validation styles to
//     var forms = document.getElementsByClassName('ais-form');
//     // alert("got " + forms.length + " ais-form");
//     // Loop over them and prevent submission
//     var validation = Array.prototype.filter.call(forms, function (form) {
//       form.addEventListener('submit', function (event) {
//         if (form.checkValidity() === false) {
//           alert('form.checkValidify === false');
//           event.preventDefault();
//           event.stopPropagation();
//         }
//         form.classList.add('was-validated');
//       }, false);
//     });
//   }, false);
// })();


// Old jQuery Validation plugin code
//     jQuery.validator.setDefaults({
//         errorElement: 'span',
//         errorPlacement: function (error, element) {
//             error.addClass('invalid-feedback');
//             element.closest('.form-group').append(error);
//         },
//         highlight: function (element, errorClass, validClass) {
//             $(element).addClass('is-invalid');
//         },
//         unhighlight: function (element, errorClass, validClass) {
//             $(element).removeClass('is-invalid');
//         }
//     });
//     $("form").validate({
//         rules: {
//             emailreg: {
//                 required: true,
//                 email: true
//             },
// //            username: {
// //                requred: true,
// //            },
// //            password: {
// //                required: true,
// //            },
//             pw1: {
//                 required: true,
//                 minlength: 8
//             },
//             pw2: {
//                 required: true,
//                 minlength: 8,
//                 equalTo: "#pw1"
//             },

//         },
//         messages: {
// //            username1: {
// //                required: "Please enter your email",
// //                minlength: "Your username must consist of at least 2 characters"
// //            },
// //            password: {
// //                required: "Please enter your password",
// //            },
//             pw1: {
//                 required: "Please provide a password",
//                 minlength: "Your password must be at least 8 characters long"
//             },
//             pw2: {
//                 required: "Please provide a password",
//                 minlength: "Your password must be at least 8 characters long",
//                 equalTo: "Please enter the same password as above"
//             },
//             emailreg: "Please enter a valid email address",
//         },
//         errorElement: "em",
//         errorPlacement: function (error, element) {
//             // Add the `help-block` class to the error element
//             error.addClass("help-block");

//             // Add `has-feedback` class to the parent div.form-group
//             // in order to add icons to inputs
// //            element.parents(".col-sm-5").addClass("has-feedback");
//             element.parents(".the-input").addClass("has-feedback");

//             if (element.prop("type") === "checkbox") {
//                 error.insertAfter(element.parent("label"));
//             } else {
//                 error.insertAfter(element);
//             }

//             // Add the span element, if doesn't exists, and apply the icon classes to it.
//             if (!element.next("span")[0]) {
//                 $("<span class='glyphicon glyphicon-remove form-control-feedback'></span>").insertAfter(element);
//             }
//         },
// //        success: function (label, element) {
// //            // Add the span element, if doesn't exists, and apply the icon classes to it.
// //            if (!$(element).next("span")[0]) {
// //                $("<span class='glyphicon glyphicon-ok form-control-feedback'></span>").insertAfter($(element));
// //            }
// //        },
// //        highlight: function (element, errorClass, validClass) {
// //            $(element).addClass("is-invalid").removeClass("is-valid");
// //            $(element).next("span").addClass("glyphicon-remove").removeClass("glyphicon-ok");
// //        },
// //        unhighlight: function (element, errorClass, validClass) {
// //            $(element).addClass("is-valid").removeClass("is-invalid");
// //            $(element).next("span").addClass("glyphicon-ok").removeClass("glyphicon-remove");
// //        }
//     });
        // END old jQuery Validation plugin code

